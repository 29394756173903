import { Delete, DocumentScanner } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useCallback, useContext, useMemo, useState } from "react";
import BoxDto from "../../models/BoxDto";
import { useConfirmBoxesScanning, useDeleteBox, useGetBoxes, useUpdateBox } from "../../services/WhReceivingService";
import { DataGridPro, GridActionsCellItem, GridColDef } from "@mui/x-data-grid-pro";
import { LayoutContext, newGuid } from "wcz-layout";
import useScanDetection from "../../utils/UseScanDetection";
import moment from "moment";

interface ScanningBoxesDialogProps {
    palletId: string,
    setPalletId: (palletId: string) => void,
    setScanningPalletDialogOpen: (open: boolean) => void,
}

export default function ScanningBoxesDialog(props: ScanningBoxesDialogProps) {
    const { user } = useContext(LayoutContext);
    const { palletId, setPalletId, setScanningPalletDialogOpen } = props;

    const [boxes, setBoxes] = useState<BoxDto[]>([]);
    const { refetch: refetchBoxes } = useGetBoxes(palletId, { enabled: !!palletId, onSuccess: (data) => { setBoxes(data); setScanningIsActive(true); }, onError: () => { } });

    const boxesTableColumns: GridColDef[] = useMemo(() => [
        { field: "boxId", headerName: "Box Id", width: 200 },
        { field: "boxIdScanDate", headerName: "Scanned", type: "boolean", valueGetter: ({ value }) => value ? true : false, width: 80 },
        { field: "boxIdRemark", headerName: "Remark", width: 200 },
        {
            field: "actions", type: "actions", width: 50, align: "right",
            getActions: (params: any) => {
                if (params.row.boxIdRemark === "newly added") {
                    return [<GridActionsCellItem dense icon={<Delete htmlColor="red" />} label="" onClick={() => handleClickDeleteBox(params.row.boxId)} />];
                }

                return [];
            }
        }
    ] as GridColDef[], [boxes]);

    const [lastScannedBoxId, setLastScannedBoxId] = useState("");
    const [scanningIsActive, setScanningIsActive] = useState(false);
    const [scanningIsConfirmed, setScanningIsConfirmed] = useState(false);

    function cleanForm() {
        setBoxes([]);
        setLastScannedBoxId("");
        setScanningIsActive(false);
        setScanningIsConfirmed(false);
    };

    function handleClickCancelDialog() {
        cleanForm();
        setPalletId("");
    };

    useScanDetection({
        //timeToEvaluate: 1000,
        //averageWaitTime: 1000,
        enabled: scanningIsActive,
        onComplete: value => handleOnScan(value)
    });

    const { mutate: updateBox } = useUpdateBox(palletId, {
        onSuccess: () => {
            refetchBoxes();
            setScanningIsConfirmed(false);
        },
    });

    const handleOnScan = useCallback((value: string) => {
        setLastScannedBoxId(value);
        //TODO: check some barcode rule

        if (boxes.some(p => p.boxId === value)) {
            let updatedBox: BoxDto = {
                boxId: value,
                boxIdScanDate: moment().format("yyyy-MM-DD HH:mm"),
                boxIdScannedBy: `${user.name} (${user.id})`
            };

            updateBox(updatedBox);
        }
        else {
            let newBox: BoxDto = {
                boxId: value,
                boxIdScanDate: moment().format("yyyy-MM-DD HH:mm"),
                boxIdScannedBy: `${user.name} (${user.id})`,
                boxIdRemark: "newly added"
            };

            updateBox(newBox);
        }
    }, [boxes]);

    const { mutate: deleteBox } = useDeleteBox(palletId, {
        onSuccess: () => {
            refetchBoxes();
            setScanningIsConfirmed(false);
        }
    });

    function handleClickDeleteBox(boxId: string) {
        deleteBox(boxId);
    }

    const { mutate: confirmScanningIsDone } = useConfirmBoxesScanning(palletId, {
        onSuccess: () => {
            refetchBoxes();
            setScanningIsConfirmed(true);
        },
    });

    function handleClickConfirmScanningIsDone() {
        setScanningIsActive(false);
        setLastScannedBoxId("");

        confirmScanningIsDone();
    }

    function handleClickScanNextPallet() {
        handleClickCancelDialog();
        setScanningPalletDialogOpen(true);
    }

    return (
        <Dialog open={!!palletId}>
            <DialogTitle><DocumentScanner sx={{ mb: "-3px" }} /> Scanning boxes on the pallet: {palletId}</DialogTitle>
            <DialogContent>
                <Box sx={{
                    display: "flex", width: "100%",
                    "& .MuiDataGrid-columnHeaderTitle": { fontWeight: "600" },
                    "& .MuiDataGrid-row:hover": { bgcolor: "white" },
                    "& .MuiDataGrid-rowBackground--lastScanned, .MuiDataGrid-rowBackground--lastScanned:hover": { bgcolor: "#B7EBE5" },
                    "& .MuiDataGrid-booleanCell[data-value='true']": { color: "green!important" },
                }}>
                    <DataGridPro columns={boxesTableColumns} rows={boxes}
                        sx={{ padding: "10px 20px", border: "0px", minHeight: "170px", maxHeight: "30vh" }}
                        getRowId={() => newGuid()}
                        density="compact"
                        hideFooter
                        disableRowSelectionOnClick
                        getRowClassName={(params) => params.row.boxId === lastScannedBoxId ? "MuiDataGrid-rowBackground--lastScanned" : ""}
                    />
                </Box>
                <Box sx={{ pl: "25px" }}>
                    Scanned boxes: {boxes.filter(box => box.boxIdScanDate).length} / {boxes.length}
                </Box>
            </DialogContent>
            <DialogActions>
                {
                    !scanningIsConfirmed ?
                        <>
                            <Button variant="text" onClick={handleClickCancelDialog}>Cancel</Button>
                            <Button variant="contained" onClick={handleClickConfirmScanningIsDone}>Confirm all boxes were scanned</Button>
                        </> :
                        <>
                            <Button variant="text" onClick={handleClickCancelDialog}>Close dialog</Button>
                            <Button variant="contained" onClick={handleClickScanNextPallet}>Scan next pallet</Button>
                        </>
                }
            </DialogActions>
        </Dialog>
    );
}
