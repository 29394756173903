import { AddAPhoto, ForwardToInbox } from "@mui/icons-material";
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import moment from "moment";
import { useContext, useState } from "react";
import { FileViewer, useFile } from "wcz-file";
import { LayoutContext, newGuid } from "wcz-layout";
import { DamageType } from "../../models/enums/DamageType";
import ReceivingsListDto from "../../models/ReceivingsListDto";
import ReceivingUpdateDto from "../../models/ReceivingUpdateDto";
import { useGetReceiving, useUpdateReceiving } from "../../services/WhReceivingService";

interface EditDialogProps {
    recordId: string,
    setRecordId: (recordId: string) => void,
}

export default function EditDialog(props: EditDialogProps) {
    const { recordId, setRecordId } = props;
    const { user } = useContext(LayoutContext);
    const [sendEmailNotificationTo, setSendEmailNotificationTo] = useState([] as string[]);

    const [receiving, setReceiving] = useState({} as ReceivingsListDto);
    useGetReceiving(recordId, {
        enabled: !!recordId,
        onSuccess: (data) => setReceiving(data)
    });

    function cleanForm() {
        setReceiving({} as ReceivingsListDto);
        setSendEmailNotificationTo([]);
    }

    function handleClickCancelDialog() {
        cleanForm();
        setRecordId("");
    };

    const { uploadProgress, uploadFiles } = useFile({ subId: receiving.attachmentId! });

    const { mutate: updateReceiving } = useUpdateReceiving();

    function handleClickUpdate() {
        let dto: ReceivingUpdateDto = {
            id: receiving.id,
            grNumber: receiving.grNumber,
            iqcStatus: receiving.iqcStatus,
            remark: receiving.remark,
            lastUpdatedDate: moment().toISOString(true),
            lastUpdatedBy: user.id,
            damageOf: receiving.damageOf,
            notificationGroups: sendEmailNotificationTo,
            attachmentId: receiving.attachmentId,
            iqcRemark: receiving.iqcRemark,
            strategicNumber: receiving.strategicNumber,
        };

        updateReceiving(dto);
        cleanForm();
        setRecordId("");
    }

    return (
        <Dialog open={!!recordId}>
            <DialogTitle>Update receiving record: {receiving.cpn}</DialogTitle>
            <DialogContent>
                <TextField id="grNumber"
                    type="text" variant="standard" margin="dense" fullWidth
                    label="GR No."
                    value={receiving?.grNumber ?? ""}
                    onChange={(event) => {
                        setReceiving({ ...receiving, grNumber: event.target.value });
                    }}
                />
                <TextField id="iqcStatus"
                    type="text" variant="standard" margin="dense" fullWidth
                    label="IQC Status"
                    value={receiving?.iqcStatus ?? ""}
                    onChange={(event) => {
                        setReceiving({ ...receiving, iqcStatus: event.target.value });
                    }}
                />
                <TextField id="remark"
                    type="text" variant="standard" margin="dense" fullWidth multiline
                    label="Remark"
                    value={receiving?.remark ?? ""}
                    onChange={(event) => {
                        setReceiving({ ...receiving, remark: event.target.value });
                    }}
                />
                <FormControl variant="standard" sx={{ mt: "10px" }} fullWidth>
                    <InputLabel id="damage" sx={{ color: "#757575" }}>Damage of</InputLabel>
                    <Select id="damage"
                        type="text" fullWidth
                        value={receiving.damageOf ?? ""}
                        onChange={(event) => {
                            setReceiving({ ...receiving, damageOf: event.target.value ?? undefined });
                        }}
                    >
                        <MenuItem value="">None</MenuItem>
                        {Object.values(DamageType).map((damage) => <MenuItem key={damage} value={damage}>{damage}</MenuItem>)}
                    </Select>
                </FormControl>
                <TextField id="iqcRemark"
                    type="text" variant="standard" margin="dense" fullWidth multiline
                    label="IQC Remark"
                    value={receiving?.iqcRemark ?? ""}
                    onChange={(event) => {
                        setReceiving({ ...receiving, iqcRemark: event.target.value });
                    }}
                />
                <TextField id="strategicNumber"
                    type="text" variant="standard" margin="dense" fullWidth
                    label="Strategic Number"
                    value={receiving?.strategicNumber ?? ""}
                    onChange={(event) => {
                        setReceiving({ ...receiving, strategicNumber: event.target.value });
                    }}
                />
                <Box sx={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
                    <ForwardToInbox fontSize="medium" sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                    <Autocomplete id="emailNotificationTo" sx={{ mt: "10px" }} multiple fullWidth
                        options={["Logistics", "Buyers", "IQC"]}
                        value={sendEmailNotificationTo ?? []}
                        onChange={(event: any, newValue: string[]) => {
                            setSendEmailNotificationTo(newValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Send E-mail notification To"
                            />
                        )}
                    />
                </Box>
                {
                    receiving.attachmentId &&
                    <Box sx={{ mt: "30px" }}>
                        <Typography variant="body1" color="text.secondary" gutterBottom component="div">Photos</Typography>
                        <FileViewer subId={receiving.attachmentId} />
                    </Box>
                }
            </DialogContent>
            <DialogActions>
                <IconButton component="label" disabled={!!uploadProgress} sx={{ marginRight: "auto", marginLeft: "15px" }} onClick={() => { !receiving.attachmentId && setReceiving({ ...receiving, attachmentId: newGuid() }); }}>
                    <input hidden accept="*" type="file" onChange={uploadFiles} />
                    <AddAPhoto />
                </IconButton>
                <Button onClick={handleClickCancelDialog}>Cancel</Button>
                <Button variant="contained" color="secondary" onClick={handleClickUpdate}>Update</Button>
            </DialogActions>
        </Dialog>
    );

}
