import { DocumentScanner } from "@mui/icons-material";
import { Box, Link, Typography } from "@mui/material";
import { DataGridPro, GridColDef, GridToolbar } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import { newGuid } from "wcz-layout";
import { useGetTodaysShipments } from "../services/EtaService";

export default function HomePage() {
    const { data: todaysShipments = [] } = useGetTodaysShipments({
        select: (data) => data.map((item) => ({ ...item, id: newGuid() })),
    });

    const columns: GridColDef[] = useMemo(() => [
        { field: "arrivalDate", headerName: "ETA", width: 250 },
        {
            field: "importNumber", headerName: "Import No", width: 150,
            renderCell: (params) => <Link href={`/detail/${params.value}/${encodeURIComponent(params.row.hawb)}`}>{params.value}</Link>
        },
        {
            field: "hawb", headerName: "HAWB", width: 250,
            renderCell: (params) => <span>{params.value} {params.row.hasPalletId && <DocumentScanner fontSize="small" sx={{ mb: "-5px" }} />}</span>
        },
        { field: "containerNumber", headerName: "Container No.", width: 250 },
        { field: "transportationNumber", headerName: "Transportation No.", width: 250 },
        { field: "packing", headerName: "Pack Unit", width: 150 },
    ] as GridColDef[], []);

    return (
        <Box>
            <Typography variant="h6" color="text.secondary" sx={{ pl: "25px", pt: "15px" }} gutterBottom component="div">Unfinished receiving (± 1 day)</Typography>
            <Box
                sx={{
                    display: "flex", width: "100%", height: { xs: "calc(100vh - 114px)", sm: "calc(100vh - 122px)" }, pt: "10px", pl: "20px", pr: "0px", pb: "5px",
                    "& .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: "600",
                    },
                }}
            >
                <DataGridPro columns={columns} rows={todaysShipments} density="compact" sx={{ border: "0px" }}
                    disableDensitySelector
                    slots={{
                        toolbar: GridToolbar,
                    }}
                    slotProps={{
                        toolbar: { showQuickFilter: true }
                    }}
                />
            </Box>
        </Box>
    );
};