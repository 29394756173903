import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useContext } from "react";
import { fetchGet, fetchPost, fetchPut, LayoutContext } from "wcz-layout";
import ImportEtaDetailUpdateDto from "../models/ImportEtaDetailUpdateDto";
import ShipmentItemDto from "../models/ShipmentItemDto";
import ShipmentListDto from "../models/ShipmentListDto";
import GridServerSideModel from "../models/dataGrid/GridServerSideModel";
import { apiUrl } from "../utils/BaseUrl";

const receivingQueryKey: string = "receiving";

export function useGetTodaysShipments<TQueryFnData = ShipmentListDto[], TError = string, TData = TQueryFnData>(options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, "queryKey" | "queryFn" | "initialData">) {
    return useQuery([receivingQueryKey], ({ signal }) => fetchGet(`${apiUrl}/eta/v1/${receivingQueryKey}/todayrange`, signal), options);
}

export function useGetShipments<TQueryFnData = ShipmentListDto[], TError = string, TData = TQueryFnData>(serverSideModel: GridServerSideModel, options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, "queryKey" | "queryFn" | "initialData">) {
    let queryParams = new URLSearchParams(serverSideModel as {}).toString();
    return useQuery([receivingQueryKey], ({ signal }) => fetchPost(`${apiUrl}/eta/v1/${receivingQueryKey}?${queryParams}`, signal), options);
}

const etaTableDetailQueryKey: string = "etatabledetail";

export function useGetShipmentItems<TQueryFnData = ShipmentItemDto[], TError = string, TData = TQueryFnData>(importNumber: string, options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, "queryKey" | "queryFn" | "initialData">) {
    return useQuery([etaTableDetailQueryKey, importNumber], ({ signal }) => fetchGet(`${apiUrl}/eta/v1/${etaTableDetailQueryKey}/${importNumber}`, signal), options);
}

interface UseUpdateEtaDetailItemsOptions {
    onSuccess?: () => void,
    onError?: (message: string) => void,
}

export function useUpdateEtaDetailItems(options?: UseUpdateEtaDetailItemsOptions) {
    const { snackbar } = useContext(LayoutContext);

    return useMutation((model: ImportEtaDetailUpdateDto[]) => fetchPut(`${apiUrl}/eta/v1/${etaTableDetailQueryKey}`, model), {
        onError: (err: string) => {
            if (options?.onError)
                options.onError(err);

            snackbar({ message: err, severity: "error" });
        },
        onSuccess: () => {
            if (options?.onSuccess)
                options.onSuccess();
        }
    });
}